<template>
  <div>
    <main-table :fields="fields" :baseURL="VUE_APP_VETE_LINK" list_url="admin/withdrawals"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'اسم العميل', key: 'customer_name', class: 'text-right' },
        { label: 'المبلغ', key: 'amount', class: 'text-right' },
        { label: 'الحالة', key: 'status', class: 'text-right' },
        { label: 'تاريخ الطلب', key: 'created_at', class: 'text-right' }
      ]
    }
  },
  methods: {
    addMerchant () {
    }
  }
}
</script>
